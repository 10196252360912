import React, { useState } from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import PageTitle from '../components/pageTitle'
import Catalogs from '../components/supportCatalogs'
import SupportSoftwares from '../components/supportSoftwares'

export default function Downloads() {
    const [tab, setTab] = useState(0)

    return (
        <Layout>
            <Seo 
                title={'Downloads Gazelle® PDF Catalogs & Software | Gazelle® Tools'}
                description={'Access Gazelle® product catalogs, manuals, and software resources. Find comprehensive details about our tools and equipment through our distributors.'}
            />
            <PageTitle
                title="Downloads"
                subtitle="You can download the Gazelle PDF Catalogs and softwares by clicking on the links below."
            />
            <div className="px-8  w-full">
                <div className='py-6 flex text-lg 2xl:text-xl  border-b border-secondary '>
                    <button
                        className={"pr-16 hover:underline hover:text-primary border-r border-secondary " + (tab === 0 ? "text-primary underline" : "")}
                        onClick={() => setTab(0)}
                    >
                        Catalogs
                    </button>
                    <button
                        className={"pl-16 hover:underline hover:text-primary " + (tab === 1 ? "text-primary underline" : "")}
                        onClick={() => setTab(1)}
                    >
                        Software
                    </button>
                </div>

            </div>
            <div className="px-8 py-6">
                {tab == 0 ?
                    <Catalogs />
                    :
                    <SupportSoftwares />
                }
            </div>
           


        </Layout>
    )
}
